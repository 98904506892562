import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/storage'
import firebaseConfig from "./config";

export default function firebaseInit() {
    // Enable Real Time Database emulator if environment variable is set
    if (process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST) {
        firebaseConfig.databaseURL = `http://${process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST}?ns=${firebaseConfig.projectId}`
        console.debug(`RTDB emulator enabled: ${firebaseConfig.databaseURL}`) // eslint-disable-line no-console
    }

    firebase.initializeApp(firebaseConfig)
    firebase.firestore()
    firebase.analytics()

    // Enable Firestore emulator if environment variable is set
    if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
        /* eslint-disable no-console */
        console.debug(
            `Firestore emulator enabled: ${process.env.REACT_APP_FIRESTORE_EMULATOR_HOST}`
        )
        /* eslint-enable no-console */
        firebase.firestore().settings({
            host: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
            ssl: false
        })
    }
}
