import {BrandsList, CarouselProps, ItemProps, ItemPropsList, ProductType} from "../types/types";
import firebase from 'firebase/app'

export const buildBrands = async () => {
    const categories: Map<ProductType, BrandsList> = new Map<ProductType, BrandsList>()

    const types: ProductType[] = [
        ProductType.Kitchens,
        ProductType.Projects,
        ProductType.Catalogs
    ] as ProductType[]


    for (let i in types) {
        const storageRef = await firebase.storage().ref(`${types[i]}`);

        await storageRef.listAll().then(function (result) {

            const brands: string[] = [] as string[]

            result.prefixes.forEach(function (ref) {
                brands.push(ref.fullPath.split("/")[1])
            })
            categories.set(types[i], {brands: brands})
        })
    }

    console.log(categories)

    return categories
}


export const buildImageUrls = async (type: ProductType, state: Map<ProductType, BrandsList> = new Map<ProductType, BrandsList>()) => {
    const imgW = "100%";
    const imgH = "auto";
    const imgProp: CarouselProps = {
        width: imgW,
        height: imgH,
    }
    const products: ItemPropsList[] = [] as ItemPropsList[]


    const category = state?.get(type);
    if (category === undefined) {
        return products
    }
    const categoryBrands = category.brands

    // https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/Catalogs%2FAstra%2Fimage.webp?alt=media

    for (const i in categoryBrands) {

        const storageRef = await firebase.storage().ref(`${type}/${categoryBrands[i]}/`);
        const items: ItemProps[] = [] as ItemProps[]

        await storageRef.listAll().then(function (result) {

            result.items.forEach(function (imageRef) {
                items.push({
                    imgURL: `https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/${type}%2F${categoryBrands[i]}%2F${imageRef.name}?alt=media`,
                    props: imgProp,
                })
            });
            products.push({
                name: category?.brands[i] as string,
                props: items,
            })
        })
    }

    return products
}

export const buildCatalogs = async (state: Map<ProductType, BrandsList> = new Map<ProductType, BrandsList>()) => {
    const imgW = "100%";
    const imgH = "auto";
    const imgProp: CarouselProps = {
        width: imgW,
        height: imgH,
    }
    const products: ItemPropsList[] = [] as ItemPropsList[]

    const category = state?.get(ProductType.Catalogs);
    if (category === undefined) {
        return products
    }
    const categoryBrands = category.brands

    // https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/Catalogs%2FAstra%2Fimage.webp?alt=media
    // https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/Catalogs%2FAstra%2Fcatalog.pdf?alt=media

    for (const i in categoryBrands) {

        products.push({
            name: category?.brands[i] as string,
            props: [
                {
                    imgURL: `https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/Catalogs%2F${category?.brands[i]}%2Fimage.webp?alt=media`,
                    props: imgProp
                },
                {
                    imgURL: `https://firebasestorage.googleapis.com/v0/b/como-web.appspot.com/o/Catalogs%2F${category?.brands[i]}%2Fcatalog.pdf?alt=media`,
                    props: imgProp
                }
            ],
        })
    }

    return products
}
