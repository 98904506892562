import React, {useState} from 'react';
import Carousel from 'react-material-ui-carousel'
import {makeStyles} from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";
import {CarouselProps, ItemProps} from "../../../types/types";

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",
        background: theme.palette.primary.dark,
        transition: 'ease-in 500ms',
    },
    img: {
        // boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
        // zIndex: 1,
    },
    indicatorIconWrapper: {
        position: "absolute",
        bottom: '0',
        top: '160',
        marginBottom: '60px',
    },
    indicatorIcon: {
        width: '40px',
        height: '40px',
        padding: '30px',
    },
    carousel: {
        height: '100vh',
        zIndex: 2,
    },
}))

type Props = {
    items: ItemProps[]
}

export const MainCarousel: React.FC<Props> = ({items}) => {
    const classes = useStyles()
    return (
        <Carousel className={classes.carousel}
                  animation={"fade"} interval={8000}
                  // navButtonsAlwaysInvisible={false}
                  // navButtonsAlwaysVisible={true}
                  indicators={false}
                  stopAutoPlayOnHover={true}

                  indicatorContainerProps={{
                      className: classes.indicatorIconWrapper,
                      style: {}
                  }}
                  indicatorIconButtonProps={{
                      className: classes.indicatorIcon,
                      style: {}
                  }}
                  activeIndicatorIconButtonProps={{
                      className: classes.indicatorIcon,
                      style: {}
                  }}

                  onChange={() => {
                      // setMql(window.matchMedia('(max-height: 801px)').matches)
                  }}
        >
            {
                items.map((item, i) => <Item key={i} props={item.props} imgURL={item.imgURL}/>)
            }
        </Carousel>
    )
}

type IProps = {
    imgURL: string
    props: CarouselProps
}

const Item: React.FC<IProps> = ({imgURL, props}) => {
    const classes = useStyles()

    return (
        <div style={{
                height: props.height,
            }}>
            <LazyLoad height={props.height} once>
                <img src={process.env.PUBLIC_URL + imgURL}
                     className={classes.img}
                     style={{
                         width: props.width,
                         height: props.height,
                     }}
                     alt={'product'}
                />
            </LazyLoad>
        </div>
    )
}

export default MainCarousel