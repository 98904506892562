import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Grid, Typography} from "@material-ui/core";
import MenuListComposition from "./components/HeaderMenu";
import {CATALOGS_PATH, CONTACT_PATH, KITCHENS_PATH, PROJECTS_PATH} from "../../config/paths";
import clsx from 'clsx';
import {Link as Scroll} from 'react-scroll'
import {ProductType} from "../../types/types";
import {useAppContext} from "../common/Context/AppContext";
import LogoComponent from "./components/Logo";

const useStyles = makeStyles((theme) => ({
    rootShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    logoContainer: {
        width: '293px',
        height: '63px',
    },
    nav: {
        width: "100%",
        margin: theme.spacing(4, 0, 0, 0),
        padding: theme.spacing(0, 4, 0, 0),
    },
    firstLinkContainer: {
        marginRight: '20px',
        cursor: "pointer",
        marginTop: '25px',
        // [theme.breakpoints.down("md")]: {
        //     marginLeft: '15px',
        // },
    },
    linkContainer: {
        marginLeft: '15px',
        marginRight: '15px',
        cursor: "pointer",
        marginTop: '25px',
    },
    lastLinkContainer: {
        marginLeft: '15px',
        cursor: "pointer",
        marginTop: '25px',
    },
    divider: {
        background: '#1D1E21',
        width: '1px',
        height: '23px',
        marginTop: '25px',
    },
    links: {
        width: "100%",
        marginLeft: 'auto',
        // transition: 'linear 500ms',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '0',
        },
    },
    linkText: {
        fontSize: '13pt',
        // [theme.breakpoints.down("md")]: {
        //     fontSize: '12pt',
        // },
    },
    link: {
        textDecoration: "none",
        color: '#1D1E21',

    },
    menuItem: {
        padding: theme.spacing(1, 2, 1, 2),
        background: theme.palette.primary.light,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        cursor: "pointer",
    },
    menuDivider: {
        background: '#1D1E21',
        width: '100%',
        height: '1px',
        backgroundColor: '#bfbfbf',
    },
    boldStyle: {
        fontWeight: 700,
    },
}))

export const Header: React.FC = () => {
    const history = useHistory()

    const {state} = useAppContext();
    const [kitchensOpen, setKitchensOpen] = React.useState(false);
    const [projectsOpen, setProjectsOpen] = React.useState(false);
    const [catalogsOpen, setCatalogsOpen] = React.useState(false);

    const [path, setPath] = useState("")
    const [top, setTop] = useState(true);

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);


    const handleLinkClick = (path: string) => {
        setPath(path)
        history.push(path)
    }

    const handleKitchensMenuClick = () => {
        setPath(KITCHENS_PATH)
        history.push(KITCHENS_PATH)
    }
    const handleProjectsMenuClick = () => {
        setPath(PROJECTS_PATH)
        history.push(PROJECTS_PATH)
    }
    const handleCatalogsMenuClick = () => {
        setPath(CATALOGS_PATH)
        history.push(CATALOGS_PATH)
    }
    const classes = useStyles()

    const linkBoldStyle = clsx(classes.linkText, classes.boldStyle);

    if (!state) {
        return <React.Fragment/>
    }
    return (
        <Grid container component={'header'} className={classes.nav} alignItems={"center"} justify={"flex-end"}
              spacing={3}>
            <Grid item>
                <LogoComponent/>
            </Grid>
            <Grid item>
                <div className={classes.linkContainer} onClick={() => handleKitchensMenuClick()}>
                    <MenuListComposition open={kitchensOpen} setOpen={setKitchensOpen} button={
                        <Typography className={path === KITCHENS_PATH ? linkBoldStyle : classes.linkText}>
                            Kitchens
                        </Typography>
                    } onClose={() => {
                    }}>
                        {state.categories.get(ProductType.Kitchens)?.brands.map((type, i) => {
                            return (
                                <Grid item key={`${type}-${i}`}>
                                    {/*<Divider classes={{root: classes.menuDivider}}/>*/}
                                    <Scroll to={type} spy={true} smooth={true} onClick={() => {
                                        setKitchensOpen(false)
                                    }}>
                                        <div className={classes.menuItem}>
                                            <Typography variant={"overline"}>
                                                {type}
                                            </Typography>
                                        </div>
                                    </Scroll>
                                </Grid>
                            )
                        })}
                    </MenuListComposition>
                </div>
            </Grid>

            <Grid item>
                <Divider orientation={"vertical"} classes={{root: classes.divider}}/>
            </Grid>

            <Grid item>
                <div className={classes.linkContainer} onClick={() => handleProjectsMenuClick()}>
                    <MenuListComposition open={projectsOpen} setOpen={setProjectsOpen} button={
                        <Typography className={path === PROJECTS_PATH ? linkBoldStyle : classes.linkText}>
                            Projects
                        </Typography>
                    } onClose={() => {
                    }}>
                        {state.categories.get(ProductType.Projects)?.brands.map((type, i) => {
                            return (
                                <Grid item key={`${type}-${i}`}>
                                    {/*<Divider classes={{root: classes.menuDivider}}/>*/}
                                    <Scroll to={type} spy={true} smooth={true} onClick={() => {
                                        setProjectsOpen(false)
                                    }}>
                                        <div className={classes.menuItem}>
                                            <Typography variant={"overline"}>
                                                {type}
                                            </Typography>
                                        </div>
                                    </Scroll>
                                </Grid>
                            )
                        })}
                    </MenuListComposition>
                </div>
            </Grid>

            <Grid item>
                <Divider orientation={"vertical"} classes={{root: classes.divider}}/>
            </Grid>

            <Grid item>
                <div className={classes.linkContainer} onClick={() => handleCatalogsMenuClick()}>
                    <MenuListComposition open={catalogsOpen} setOpen={setCatalogsOpen} button={
                        <Typography className={path === CATALOGS_PATH ? linkBoldStyle : classes.linkText}>
                            Catalogs
                        </Typography>
                    } onClose={() => {
                    }}>
                        {state.categories.get(ProductType.Catalogs)?.brands.map((type, i) => {
                            return (
                                <Grid item key={`${type}-${i}`}>
                                    {/*<Divider classes={{root: classes.menuDivider}}/>*/}
                                    <Scroll to={type} spy={true} smooth={true} onClick={() => {
                                        setCatalogsOpen(false)
                                    }}>
                                        <div className={classes.menuItem}>
                                            <Typography variant={"overline"}>
                                                {type}
                                            </Typography>
                                        </div>
                                    </Scroll>
                                </Grid>
                            )
                        })}
                    </MenuListComposition>
                </div>
            </Grid>

            <Grid item>
                <Divider orientation={"vertical"} classes={{root: classes.divider}}/>
            </Grid>
            <Grid item>
                <div className={classes.lastLinkContainer}>
                    <Link to={CONTACT_PATH} className={classes.link} onClick={() => {
                        setPath(CONTACT_PATH)
                    }}>
                        <Typography className={path === CONTACT_PATH ? linkBoldStyle : classes.linkText}
                                    onClick={() => handleLinkClick(CONTACT_PATH)}>Contact</Typography>
                    </Link>
                </div>
            </Grid>
        </Grid>
    );
}

export default Header;
