import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app'
import * as serviceWorker from './utils/serviceWorker'
import "./style.scss"
import firebaseConfig from "./firebase/config";
import { FirebaseAppProvider } from 'reactfire';

ReactDOM.render(
    (
        <App/>
    ),
    document.getElementById('root'))

serviceWorker.unregister()
