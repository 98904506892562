import React, {useEffect} from 'react';
import {createBrowserHistory} from "history";
import {Router} from 'react-router-dom';
import AOS from 'aos';
import ThemeProvider from "./config/theme/ThemeProvider";
import createRoutes from "./routes";
import firebaseInit from "firebase/firebaseInit";
import {ApplicationContext} from "./components/common/Context/AppContext";

declare global {
    interface Window {
        __INITIAL_STATE__: any;
        __REDUX_DEVTOOLS_EXTENSION__: any;
        Cypress: any;
    }
}

firebaseInit();
AOS.init({
    once: true,
    disable: 'phone',
    duration: 700,
    easing: 'ease-out-cubic',
});

const history = createBrowserHistory();
const App = () => {

    const routes = createRoutes()

    return (
        <React.StrictMode>
            <ThemeProvider>
                <ApplicationContext>
                    <Router history={history}>
                        {routes}
                    </Router>
                </ApplicationContext>
            </ThemeProvider>
        </React.StrictMode>
    );
}

export default App;