export default {
    palette: {
        primary: {
            main: '#F5EFE1',
            light: '#F5EFE1',
            dark: '#F5EFE1',
            contrastText: '#162b2b',
        },
    },
    typography: {
        "fontFamily": `"", "HKGroteskREG", "HKGroteskB"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        "fontWeightBold": 900
    },
    flexColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    flexRowCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
}
