export enum ProductType {
    Kitchens = "Kitchens",
    Projects = "Projects",
    Catalogs = "Catalogs",
}

export interface ImageType {
    img: string;
    title: string;
    rows: number;
    cols: number;
}

export interface Product {
    id: string;
    title: string;
    createdAt: string;
    imgURLs: string[];
    data: string;
    type: ProductType;
}

export type CarouselProps = {
    width: string
    height: string
}

export type ItemProps = {
    imgURL: string
    props: CarouselProps
}

export type ItemPropsList = {
    name: string
    props: ItemProps[]
}

export type BrandsList = {
    brands: string[]
}

export interface AppContext {
    categories: Map<ProductType, BrandsList>
}
export declare const DefaultAppContext: AppContext;
