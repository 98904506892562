import React, {FunctionComponent} from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // transition: 'linear 2500ms',
        zIndex: 6,
        opacity: 1,
    },
    button: {
        borderWidth: '0px',
        cursor: "pointer",
        background: 'rgba(0,0,0,0.0)',
    },
    list: {
        padding: '0px',
    },
}))


type MenuListCompositionProps = {
    button: React.ReactElement
    onClose: () => void
    open: boolean
    setOpen: (open: boolean) => void
}
export const MenuListComposition: FunctionComponent<MenuListCompositionProps> =
    ({
         children,
         open,
         setOpen,
         button,
         onClose
     }) => {
        const anchorRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

        const handleToggle = () => {
            setOpen(!open);
        };

        const handleClose = (e: React.MouseEvent<Document, MouseEvent>) => {
            const inputElement = anchorRef.current
            if (inputElement && inputElement.contains(e.target as Node)) {
                return;
            }
            setOpen(false);
            onClose()
        };

        function handleListKeyDown(event: React.KeyboardEvent) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            }
        }

        // return focus to the button when we transitioned from !open -> open
        const prevOpen = React.useRef(open);
        React.useEffect(() => {
            const inputElement = anchorRef.current

            if (prevOpen.current && !open) {
                if (inputElement) {
                    inputElement.focus();
                }
            }

            prevOpen.current = open;
        }, [open]);
        const classes = useStyles()

        return (
            <div className={classes.root}>
                <button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button}
                >
                    <>
                        {button}
                    </>
                </button>
                <Popper open={open} className={classes.root} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <Grid container justify={"center"}>
                                            {children}
                                        </Grid>
                                    </MenuList>
                                </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }

export default MenuListComposition