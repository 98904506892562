import React, {Suspense} from 'react'
import {Route, useRouteMatch} from 'react-router-dom'
import LoadingSpinner from "../components/common/LoadingSpinner/LoadingSpinner";

export function renderChildren(routes: any[], parentProps?: any) {
    return routes.map((route) => {
        const match = useRouteMatch()
        return (
            <Route
                key={`${match.url}-${route.path}`}
                path={`${match.url}/${route.path}`}
                render={(props) => <route.component {...parentProps} {...props} />}
            />
        )
    })
}

/**
 * Create component which is loaded async, showing a loading spinner
 * in the meantime.
 * @param {Function} loadFunc - Loading options
 * @returns {React.Component}
 */
export function loadable(loadFunc: any) {
    const OtherComponent = React.lazy(loadFunc)
    return function LoadableWrapper(loadableProps: any) {
        return (
            <Suspense fallback={<LoadingSpinner/>}>
                <OtherComponent {...loadableProps} />
            </Suspense>
        )
    }
}
