import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LandingRoutes from "./Landing";
import CatalogRoutes from "./Catalogs";
import ProductRoutes from "./Products";
import ProjectRoutes from "./Projects";
import NotFoundRoute from "./NotFound";
import Layout from "../components/Layout";

export default function createRoutes() {
    return (
        <Layout>
            <Switch>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <Route exact path={LandingRoutes.home.path} component={() => <LandingRoutes.home.component/>}/>
                {
                    [
                        LandingRoutes.contact,
                        CatalogRoutes,
                        ProductRoutes,
                        ProjectRoutes,
                    ].map((settings) =>
                        <Route key={`Route-${settings.path}`} {...settings} />
                    )
                }
                <Route component={NotFoundRoute.component}/>
            </Switch>
        </Layout>
    )
}
