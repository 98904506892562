import React from 'react';
import {Divider, Grid, Link, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 0, 2, 0),
        zIndex: 1,
        backgroundColor: '#1d1e21',
        minHeight: "12vh",
    },
    container: {
        margin: theme.spacing(0, 12, 0, 4),
    },
    top: {
        width: "100%",
    },
    wrapper: {},
    icon: {
        color: '#F5EFE1',
    },
    divider: {
        backgroundColor: '#F5EFE1',
        width: "90%",
        height: "1px",
        margin: theme.spacing(1, 0, 1, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 1, 0),
        },
    },
    logo: {
        filter: 'invert(100%) sepia(0%) saturate(528%) hue-rotate(319deg) brightness(108%) contrast(92%)',
        height: "46px",
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1, 0),

        },
    },
    titleText: {
        color: '#F5EFE1',
        fontSize: 12,
        fontWeight: 900,
        margin: theme.spacing(0, 0, 1, 0),

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 1, 0),
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2, 0, 1, 0),
        },
    },
    text: {
        color: '#F5EFE1',
        fontSize: 10,
        fontWeight: 100,
        wordWrap: "break-word"
    },
    footerText: {
        color: '#F5EFE1',
        cursor: "default",
        fontSize: 9,
    },
}))

export const Footer: React.FC = () => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.container}>
                <Grid container className={classes.top} alignItems={"flex-end"} justify={"flex-start"}>
                    <Grid item xs={12} sm={3} lg={2}>
                        <img className={classes.logo} alt="logo"
                             src={process.env.PUBLIC_URL + "/images/logo.svg"}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Grid container>
                            <Grid item xs={2} sm={1} md={4} lg={3}>
                                <Typography className={classes.text}>
                                    <FacebookOutlinedIcon className={classes.icon}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={1} md={4} lg={3}>
                                <Typography className={classes.text}>
                                    <InstagramIcon className={classes.icon}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={1} md={4} lg={3}>
                                <Typography className={classes.text}>
                                    <TwitterIcon className={classes.icon}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                        <Grid container>
                            <Typography className={classes.titleText}>
                                Address
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography className={classes.text}>
                                364 Upper Richmond Road West,
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography className={classes.text}>
                                East Sheen, SW14 7JU, London
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1}>
                        <Grid container>
                            <Typography className={classes.titleText}>
                                Contact
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography className={classes.text}>
                                020 8876 8756
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography className={classes.text}>
                                info@comokitchens.uk
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Divider className={classes.divider} color={'#F5EFE1'}/>
                </Grid>
                <Grid container>
                    <Typography className={classes.footerText}>
                        © Como design 2020. Crafted by <Link href="https://dev.knolip.com" underline="none">Knolip</Link>.
                        All right reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
