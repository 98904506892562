import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../types/types";
import {buildBrands} from "../../../utils/imagesProvider";

interface IContextValue {
    state: AppContext | null
    setState: Dispatch<SetStateAction<AppContext | null>>
}

export const AppCtx = createContext<IContextValue | null>(null);

export const useAppContext = () => {
    const context = useContext(AppCtx)

    if (!context) {
        throw new Error("app context is not defined")
    }
    return context
}

export const ApplicationContext: React.FC = ({children}) => {

    const [state, setState] = useState<AppContext | null>(null);
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        const init = async () => {
            const categories = await buildBrands()
            setState({categories: categories})
            setLoading(false)
        }
        init()
    }, [])

    return (
        <AppCtx.Provider value={{state, setState}}>
            {children}
        </AppCtx.Provider>
    );
};
