import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import DefaultTheme from './themes/default'

export const ThemeProvider: React.FC = ({children}) => {
    const theme = React.useMemo(
        () => {
            return createMuiTheme({
                ...DefaultTheme,
            })
        },
        []
    )
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeProvider
