import {CONTACT_PATH, HOME_PATH} from 'config/paths'
import {loadable} from 'utils/router'

export default {
    home: {
        path: HOME_PATH,
        component: loadable(() =>
            import('../../components/Home')
        )
    },
    contact: {
        path: CONTACT_PATH,
        component: loadable(() =>
            import('../../components/Contact')
        )
    },
}
