import React, {FunctionComponent} from 'react'
import Header from "../Header";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import {MainCarousel} from "../common/MainCarousel";
import {ItemProps} from "../../types/types";
import VerticalHeader from "../Header/components/VerticalHeader";
import clsx from "clsx";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "88vh",
        overflow: "hidden",
        background: theme.palette.primary.main,
        color: '#1D1E21',
    },
    rootBackground: {
        background: theme.palette.primary.main,
    },
    headerOpen: {
        minHeight: "100vh",
        background: theme.palette.primary.dark,
    },
    main: {
        width: "90%",
        marginTop: '15vh',
        marginBottom: '10vh',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '12vh',
        // "@media (max-height: 801px)": {
        //     marginTop: '5%',
        // }
    },

    logo: {
        width: '293px',
        height: '63px',
        position: 'absolute',
        margin: theme.spacing(4, 0, 0, 3)
    },
    content: {
        width: "60%",
        [theme.breakpoints.down("md")]: {
            width: '100%',
        },
    },
    contentBar: {
        width: "100%",
        height: '40px',
    },
    imgBar: {
        width: "40%",
        position: "fixed",
        right: 0,
        background: '#111',
    },
}))

const Layout: FunctionComponent = ({children}) => {

    const imgW = "100%";
    const imgH = "100vh";

    var items: ItemProps[] = [
        {
            imgURL: "/images/1.webp",
            props: {
                width: imgW,
                height: imgH,
            },
        },
        {
            imgURL: "/images/2.webp",
            props: {
                width: imgW,
                height: imgH,
            },
        },
        {
            imgURL: "/images/3.webp",
            props: {
                width: imgW,
                height: imgH,
            },
        },
    ]

    const toggleVHeader = () => {
        setHeaderOpen(open => !open)
    }

    const [headerOpen, setHeaderOpen] = React.useState(false);

    const classes = useStyles()

    let showBasicHeader = useMediaQuery('(max-width: 880px)');

    let rootStyle
    if (showBasicHeader && headerOpen) {
        rootStyle = clsx(classes.root, classes.headerOpen)
    } else {
        rootStyle = clsx(classes.root, classes.rootBackground)
        if (headerOpen) {
            toggleVHeader()
        }
    }

    return (
        <>
            <Grid container className={rootStyle}>
                <Grid item className={classes.content}>
                    {showBasicHeader ? <VerticalHeader toggleHeader={toggleVHeader}/> : <Header/>}
                    {!headerOpen && (
                        <main className={classes.main}>
                            {children}
                        </main>
                    )}
                </Grid>
                <Hidden mdDown>
                    <Grid item className={classes.imgBar}>
                        <MainCarousel items={items}/>
                    </Grid>
                </Hidden>
            </Grid>
            {!headerOpen && (
                <Grid container>
                    <Footer/>
                </Grid>
            )}
        </>
    );
}

export default Layout
