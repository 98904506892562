import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'

type LoadingSpinnerProps = {
    size?: number
}

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed' as 'fixed',
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        opacity: 0,
        zIndex: 3
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50%'
    },
}))

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({size = 120}) => {
    const classes = useStyles()

    return (
        <div className={classes.overlay}>
            <div className={classes.progress}>
                <CircularProgress variant="indeterminate" size={size} color={"secondary"}/>
            </div>
        </div>
    )
}
export default LoadingSpinner
