import React, {FunctionComponent} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";
import {Link} from "react-router-dom";
import {HOME_PATH} from "../../../config/paths";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '161px',
        height: '88px',
        zIndex: 10,
        position: 'absolute',
        // todo:
        marginTop: 'auto',
        marginBottom: 'auto',
        left: 20,
        top: 30,
        "@media (max-width: 880px)": {
            position: 'absolute',
        }
    },
    link: {
        textDecoration: "none",
        color: '#1D1E21',
    },
}))


type Props = {}
export const LogoComponent: FunctionComponent<Props> = ({children}) => {

    const classes = useStyles()

    return (
        <LazyLoad height={'33px'}>
            <Link to={HOME_PATH} className={classes.link}>
                <img className={classes.logo} alt="logo"
                     src={process.env.PUBLIC_URL + "/images/logo.svg"}/>
            </Link>
        </LazyLoad>
    );
}

export default LogoComponent