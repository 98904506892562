import React, {useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {Divider, Grid, Typography} from "@material-ui/core";
import MenuListComposition from "./HeaderMenu";
import {CATALOGS_PATH, CONTACT_PATH, HOME_PATH, KITCHENS_PATH, PROJECTS_PATH} from "../../../config/paths";
import clsx from 'clsx';
import {Squeeze} from 'hamburger-react'
import {AppCtx, useAppContext} from "../../common/Context/AppContext";
import {ProductType} from "../../../types/types";
import LogoComponent from "./Logo";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 5,
        paddingLeft: '2%',
        paddingRight: '2%',
        paddingTop: '2.4rem',
        paddingBottom: '1rem',
        width: "60%",
        [theme.breakpoints.up("xl")]: {
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        // transition: 'ease-in 500ms',
        position: 'fixed',
    },
    headerClosed: {
        background: theme.palette.primary.main,
    },
    headerOpen: {
        height: "100vh",
        overflow: "hidden",
        zIndex: 5,
    },
    headerOpenWrapper: {
        margin: theme.spacing(2, 0, 12, 0),
    },
    button: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    nav: {
        width: "100%",
        height: "100%",
    },
    firstLinkContainer: {
        marginRight: '4%',
        cursor: "pointer",
        marginTop: '25px',
        [theme.breakpoints.down("md")]: {
            marginLeft: '3%',
        },
    },
    linkContainer: {
        marginLeft: '3%',
        marginRight: '3%',
        cursor: "pointer",
        marginTop: '25px',
    },
    divider: {
        background: '#1D1E21',
        width: '1px',
        height: '23px',
        marginTop: '12px',
    },
    links: {
        width: "100%",
        marginLeft: 'auto',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '0',
        },
    },
    linkText: {
        fontSize: '40pt',
        fontWeight: 100,
        textDecoration: "none",
        color: '#1D1E21',
        lineHeight: '100%',
    },
    boldStyle: {
        fontWeight: 900,
    },
    link: {
        textDecoration: "none",
        color: '#1D1E21',
    },
    menuWrapper: {
        textDecoration: "none",
        color: theme.palette.primary.contrastText,
    },
    menuItem: {
        paddingLeft: '6px',
        paddingRight: '6px',
        background: theme.palette.primary.light,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        cursor: "pointer",
    },
    menuText: {
        lineHeight: 1.66,
    },
    menuDivider: {
        background: '#1D1E21',
        width: '100%',
        height: '1px',
        backgroundColor: theme.palette.primary.main,
        // marginTop: '25px',
    },
    entryWrap: {
        padding: theme.spacing(3, 0, 3, 0)
    },
}))

interface IProps {
    toggleHeader: () => void
}

export const VerticalHeader: React.FC<IProps> = ({toggleHeader}) => {
    const history = useHistory()

    const {state} = useAppContext();

    const [headerOpen, setHeaderOpen] = React.useState(false);
    const [kitchensOpen, setKitchensOpen] = React.useState(false);
    const [projectsOpen, setProjectsOpen] = React.useState(false);
    const [catalogsOpen, setCatalogsOpen] = React.useState(false);

    const handleLinkClick = (path: string) => {
        history.push(path)
        setHeaderOpen(false)
        toggleHeader()
    }
    const handleKitchensMenuClick = () => {
        history.push(KITCHENS_PATH)
    }
    const handleKitchensMenuOptionClick = (type: string) => {
        setKitchensOpen(false)
        toggleHeader()
        setHeaderOpen(false)
    }

    const handleProjectsMenuClick = () => {
        history.push(PROJECTS_PATH)
    }
    const handleProjectsMenuOptionClick = (type: string) => {
        setProjectsOpen(false)
        toggleHeader()
        setHeaderOpen(false)
    }

    const handleCatalogsMenuClick = () => {
        history.push(CATALOGS_PATH)
    }
    const handleCatalogsMenuOptionClick = (type: string) => {
        setCatalogsOpen(false)
        toggleHeader()
        setHeaderOpen(false)
    }

    const classes = useStyles()

    let rootStyle
    if (headerOpen) {
        rootStyle = clsx(classes.root, classes.headerOpen)
    } else {
        rootStyle = clsx(classes.root, classes.headerClosed)
    }

    const linkBoldStyle = clsx(classes.linkText, classes.boldStyle);


    if (!state) {
        return <React.Fragment/>
    }
    return (
        <header>
            <Grid container className={rootStyle} component={'nav'} alignItems={"flex-start"} justify={"center"}>
                <LogoComponent/>

                <Grid container alignItems={"center"} justify={"flex-end"}>
                    <Grid item className={classes.button}>
                        <Squeeze toggle={setHeaderOpen} toggled={headerOpen} onToggle={() => {
                            toggleHeader()
                        }}/>
                    </Grid>
                </Grid>

                {headerOpen && (
                    <Grid container className={classes.headerOpenWrapper} alignItems={"flex-end"} justify={"center"}>
                        <Grid className={classes.entryWrap} container alignItems={"center"} justify={"center"}>
                            <Link to={HOME_PATH} className={classes.link}>
                                <Typography
                                    className={history.location.pathname === HOME_PATH ? linkBoldStyle : classes.linkText}
                                    onClick={() => handleLinkClick(HOME_PATH)}>Home</Typography>
                            </Link>
                        </Grid>

                        <Grid container className={classes.entryWrap} alignItems={"center"} justify={"center"}
                              onClick={() => handleKitchensMenuClick()}>
                            <MenuListComposition open={kitchensOpen} setOpen={setKitchensOpen} button={
                                <Typography
                                    className={history.location.pathname === KITCHENS_PATH ? linkBoldStyle : classes.linkText}>
                                    Kitchens
                                </Typography>
                            } onClose={() => {
                            }}>
                                {state.categories.get(ProductType.Kitchens)?.brands.map((type, i) => {
                                    return (
                                        <a href={KITCHENS_PATH + "#" + type} key={`${type}-${i}`}
                                           className={classes.menuWrapper}
                                           onClick={() => {
                                               handleKitchensMenuOptionClick(type)
                                           }}>
                                            {i !== 0 && (
                                                <Divider classes={{root: classes.menuDivider}}/>
                                            )}
                                            <div className={classes.menuItem}>
                                                <Typography variant={"overline"} className={classes.menuText}>
                                                    {type}
                                                </Typography>
                                            </div>
                                        </a>
                                    )
                                })}
                            </MenuListComposition>
                        </Grid>


                        <Grid container className={classes.entryWrap} alignItems={"center"} justify={"center"}
                              onClick={() => handleProjectsMenuClick()}>
                            <MenuListComposition open={projectsOpen} setOpen={setProjectsOpen} button={
                                <Typography
                                    className={history.location.pathname === PROJECTS_PATH ? linkBoldStyle : classes.linkText}>
                                    Projects
                                </Typography>
                            } onClose={() => {
                            }}>
                                {state.categories.get(ProductType.Projects)?.brands.map((type, i) => {
                                    return (
                                        <a href={PROJECTS_PATH + "#" + type} key={`${type}-${i}`}
                                           className={classes.menuWrapper}
                                           onClick={() => {
                                               handleProjectsMenuOptionClick(type)
                                           }}>
                                            {i !== 0 && (
                                                <Divider classes={{root: classes.menuDivider}}/>
                                            )}
                                            <div className={classes.menuItem}>
                                                <Typography variant={"overline"} className={classes.menuText}>
                                                    {type}
                                                </Typography>
                                            </div>
                                        </a>
                                    )
                                })}
                            </MenuListComposition>
                        </Grid>

                        <Grid container className={classes.entryWrap} alignItems={"center"} justify={"center"}
                              onClick={() => handleCatalogsMenuClick()}>
                            <MenuListComposition open={catalogsOpen} setOpen={setCatalogsOpen} button={
                                <Typography
                                    className={history.location.pathname === CATALOGS_PATH ? linkBoldStyle : classes.linkText}>
                                    Catalogs
                                </Typography>
                            } onClose={() => {
                            }}>
                                {state.categories.get(ProductType.Catalogs)?.brands.map((type, i) => {
                                    return (
                                        <a href={CATALOGS_PATH + "#" + type} key={`${type}-${i}`}
                                           className={classes.menuWrapper}
                                           onClick={() => {
                                               handleCatalogsMenuOptionClick(type)
                                           }}>
                                            {i !== 0 && (
                                                <Divider classes={{root: classes.menuDivider}}/>
                                            )}
                                            <div className={classes.menuItem}>
                                                <Typography variant={"overline"} className={classes.menuText}>
                                                    {type}
                                                </Typography>
                                            </div>
                                        </a>
                                    )
                                })}
                            </MenuListComposition>
                        </Grid>

                        <Grid className={classes.entryWrap} container alignItems={"center"} justify={"center"}>
                            <Link to={CONTACT_PATH} className={classes.link}>
                                <Typography
                                    className={history.location.pathname === CONTACT_PATH ? linkBoldStyle : classes.linkText}
                                    onClick={() => handleLinkClick(CONTACT_PATH)}>Contact</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                )}

            </Grid>
        </header>
    );
}

export default VerticalHeader;
